import { FeatureCollection } from "geojson-iots";
import Map from "ol/Map.js";
import View from "ol/View.js";
import SourceTileWMS from "ol/source/TileWMS.js";
import SourceXYZ from "ol/source/XYZ.js";
import LayerTile from "ol/layer/Tile.js";
import TileLayer from "ol/layer/Tile.js";
import VectorLayer from "ol/layer/Vector.js";
import OSM from "ol/source/OSM.js";
import { toLonLat } from "ol/proj.js";
import VectorSource from "ol/source/Vector.js";
import Style from "ol/style/Style.js";
import Stroke from "ol/style/Stroke.js";
import Fill from "ol/style/Fill.js";
import Feature from "ol/Feature.js";
import GeoJSON from "ol/format/GeoJSON.js";
import Select, { SelectEvent } from "ol/interaction/Select.js";
import CircleStyle from "ol/style/Circle.js";
import TextStyle from "ol/style/Text.js";
import BaseLayer from "ol/layer/Base.js";
import { Point } from "ol/geom.js";
import { MultiPolygon } from "ol/geom.js";

const elemFacto =
  <K extends keyof HTMLElementTagNameMap>(tag: K) =>
  (className: string, ...elements: (Element | Text)[]) => {
    const e = document.createElement(tag);
    e.setAttribute("class", className);
    e.append(...elements);
    return e;
  };

export const events = <E extends HTMLElement>(
  e: E,
  f: (
    s: <K extends keyof HTMLElementEventMap>(
      k: K,
      listener: (ev: HTMLElementEventMap[K]) => void
    ) => void
  ) => void
) => {
  const add = <K extends keyof HTMLElementEventMap>(
    k: K,
    listener: (ev: HTMLElementEventMap[K]) => void
  ) => {
    e.addEventListener(k, listener);
  };
  f(add);
  return e;
};

const div = elemFacto("div");
const span = elemFacto("span");
const anchor = elemFacto("a");
const baseInput = elemFacto("input");
const baseLabel = elemFacto("label");

const img = (src: string) => {
  const e = document.createElement("img");
  e.setAttribute("src", src);
  return e;
};

const link = (name: string, url: string, className = "") => {
  const e = anchor(className, text(name));
  e.setAttribute("href", url);
  return e;
};

const input = (type: string, className = "") => {
  const e: HTMLInputElement = baseInput(className);
  e.setAttribute("type", type);
  return e;
};

const label = (for_: string, txt: string, className = "") => {
  const e = baseLabel(className, text(txt));
  e.setAttribute("for", for_);
  return e;
};

const text = (content: string) => document.createTextNode(content);

const formatCoord = (x: number, y: number) => {
  const [tx, ty] = toLonLat([x, y]);
  return `${tx.toFixed(5)} ${ty.toFixed(5)}`;
};

export function removeElement(elem: Node, keepChildren = false) {
  if (!keepChildren) {
    emptyElement(elem);
  }
  const parent = elem.parentNode;
  if (parent) {
    parent.removeChild(elem);
  }
}

export function emptyElement(elem: Node) {
  while (elem.firstChild) {
    removeElement(elem.firstChild);
  }
  return elem;
}

type FType = "building" | "zone";

const makeBuildingLayer = (buildings: FeatureCollection) => {
  const source = new VectorSource({
    features: new GeoJSON().readFeatures(buildings, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    }),
  });
  const layer = new VectorLayer({
    source,
    style: (feature) => {
      const props = feature.getProperties();
      const exist = "exist" in props && props.exist;
      const text = new TextStyle({
        text: props.folder,
        textBaseline: "bottom",
        offsetY: -10,
      });
      const image = (() => {
        if (exist) {
          return new CircleStyle({
            radius: 8,
            fill: new Fill({
              color: "#ff5600",
            }),
          });
        }
        return new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: "white",
          }),
          stroke: new Stroke({
            color: "#ff5600",
            width: 2,
          }),
        });
      })();

      return new Style({ image, text });
    },
  });

  return layer;
};
const makeZoneLayer = (zones: FeatureCollection) => {
  const source = new VectorSource({
    features: new GeoJSON().readFeatures(zones, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    }),
  });
  const layer = new VectorLayer({
    source,
    style: (feature) => {
      const props = feature.getProperties();
      const name = props.name;
      // const folder = props.folder;

      const text = new TextStyle({
        text: name,
        textBaseline: "bottom",
      });

      return new Style({
        stroke: new Stroke({
          color: "#ff5600",
          width: 2,
        }),
        fill: new Fill({
          color: "#ffffff2b",
        }),
        text,
      });
    },
  });

  return layer;
};

const baseLayerSwitcher = (layers: BaseLayer[]) => {
  const elements = layers.map((layer) => {
    const name = layer.get("name");
    const checkbox = events(input("checkbox"), (add) =>
      add("change", () => {
        layer.setVisible(!layer.getVisible());
      })
    );
    checkbox.id = name;
    if (layer.getVisible()) {
      checkbox.setAttribute("checked", "");
    }
    return div("layer", checkbox, label(name, name));
  });

  return div("bl-switch", ...elements);
};

const initMap = (
  root: HTMLElement,
  buildings: FeatureCollection,
  zones: FeatureCollection
) => {
  const osmLayer = new TileLayer({
    source: new OSM(),
    visible: false,
  });
  osmLayer.set("name", "OpenStreeMap");

  const gLayer = new TileLayer({
    source: new SourceXYZ({
      url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    }),
    visible: false,
  });
  gLayer.set("name", "google satellite");

  const acLayer = new LayerTile({
    source: new SourceTileWMS({
      projection: "EPSG:3857",
      params: {
        LAYERS: "medina-marrakesh",
        VERSION: "1.3.0",
        TILED: true,
      },
      url: "https://wms.atelier-cartographique.be/service",
      crossOrigin: "Anonymous",
    }),
  });
  acLayer.set("name", "atelier carto");

  const buildingLayer = makeBuildingLayer(buildings);
  const zonesLayer = makeZoneLayer(zones);

  const view = new View({
    center: [-889130.1936800188, 3714851.0144527974],
    zoom: 17,
  });

  const map = new Map({
    target: root,
    layers: [acLayer, gLayer, osmLayer, zonesLayer, buildingLayer],
    view,
  });

  //   map.on("singleclick", (event: { coordinate: [any, any] }) => {
  //     const [x, y] = event.coordinate;
  //     handler(view, x, y);
  //   });

  //   const toggleOsm = div("switch");
  //   toggleOsm.append(text("OSM"));
  //   toggleOsm.addEventListener("click", () => {
  //     if (osmLayer.getVisible()) {
  //       osmLayer.setVisible(false);
  //       toggleOsm.classList.remove("selected");
  //     } else {
  //       osmLayer.setVisible(true);
  //       toggleOsm.classList.add("selected");
  //     }
  //   });
  //   document.body.append(toggleOsm);

  root.append(baseLayerSwitcher([osmLayer, gLayer]));
  return map;
};

const colnames = [
  //   "folder",
  "neighbourhood",
  "name",
  "type",
  "subtype",
  // "lat",
  // "lon",
  "firstname",
  "lastname",
  "year",
];

const renderProp = (
  props: {
    [x: string]: any;
  },
  key: string
) => {
  const value = props[key];
  if (value) {
    return div(
      "entry",
      div("key", text(key)),
      div("value", text(value as string))
    );
  }
  return div("entry empty", div("key", text(key)));
};

const make_thumbnails = (ftype: FType, folder: string, images: string[]) =>
  images.map((name: string) => {
    const image = img(
      `/thumbnail/${ftype}/${encodeURIComponent(folder)}/${encodeURIComponent(
        name
      )}`
    );
    image.setAttribute("loading", "lazy");
    // image.width = 300;
    image.height = 300;
    image.addEventListener("load", () => {
      image.height = image.naturalHeight;
    });
    return div(
      "thumbnail",
      div(
        "name",
        link(
          name,
          `/media/${ftype}/${encodeURIComponent(folder)}/${encodeURIComponent(
            name
          )}`
        )
      ),
      image
    );
  });

const make_documents = (ftype: FType, folder: string, documents: string[]) =>
  documents.map((name: string) =>
    div(
      "document",
      div(
        "name",
        link(
          name,
          `/media/${ftype}/${encodeURIComponent(folder)}/${encodeURIComponent(
            name
          )}`
        )
      )
    )
  );

type DirData = {
  name: string;
  images: string[];
  documents: string[];
};

const renderSelectedPoint = (
  info: HTMLElement,
  feature: Feature<Point>,
  zoomToFeature: (feature: Feature<Point>) => void
) => {
  emptyElement(info);
  const props = feature.getProperties();
  const folder: string = props.folder;

  const es = [
    div(
      "folder",
      link(
        folder,
        `https://medina.atelier-cartographique.be/nextcloud/index.php/apps/files/?dir=/archive-bati/batiments/${encodeURIComponent(
          folder
        )}`
      )
    ),
    events(div("zoom", text("↬ sur la carte")), (add) =>
      add("click", () => {
        zoomToFeature(feature);
      })
    ),
  ]
    .concat(colnames.map((key) => renderProp(props, key)))
    .concat(make_documents("building", folder, props.documents))
    .concat(make_thumbnails("building", folder, props.images));

  props.dirs.map((name: string) => {
    const element = div(
      "dir",
      events(div("name", text(name)), (add) =>
        add("click", ({ target }) => {
          fetch(
            `/dir/building/${encodeURIComponent(folder)}/${encodeURIComponent(
              name
            )}`
          )
            .then((r) => r.json())
            .then((data: DirData) => {
              const ds = make_documents(
                "building",
                `${folder},${data.name}`,
                data.documents
              ).concat(
                make_thumbnails(
                  "building",
                  `${folder};${data.name}`,
                  data.images
                )
              );
              element.append(div("dirlist", ...ds));
            });
        })
      )
    );
    es.push(element);
  });
  info.append(...es);
};

const renderSelectedPolygon = (
  info: HTMLElement,
  feature: Feature<MultiPolygon>
) => {
  emptyElement(info);
  const props = feature.getProperties();
  const folder: string | null = props.folder;
  if (folder === null) {
    return;
  }

  const es = [
    div(
      "folder",
      link(
        props.name ?? folder,
        `https://medina.atelier-cartographique.be/nextcloud/index.php/apps/files/?dir=/archive-bati/lieux-publics/${encodeURIComponent(
          folder
        )}`
      )
    ),
  ]
    .concat(make_documents("zone", folder, props.documents))
    .concat(make_thumbnails("zone", folder, props.images));

  props.dirs.map((name: string) => {
    const element = div(
      "dir",
      events(div("name", text(name)), (add) =>
        add("click", ({ target }) => {
          fetch(
            `/dir/zone/${encodeURIComponent(folder)}/${encodeURIComponent(
              name
            )}`
          )
            .then((r) => r.json())
            .then((data: DirData) => {
              const ds = make_documents(
                "zone",
                `${folder},${data.name}`,
                data.documents
              ).concat(
                make_thumbnails("zone", `${folder};${data.name}`, data.images)
              );
              element.append(div("dirlist", ...ds));
            });
        })
      )
    );
    es.push(element);
  });
  info.append(...es);
};

const numberFormat = new Intl.NumberFormat("fr-BE", {
  maximumSignificantDigits: 8,
});

export const main = (
  root: HTMLElement,
  info: HTMLElement,
  buildings: FeatureCollection,
  zones: FeatureCollection
) => {
  const map = initMap(root, buildings, zones);

  const select = new Select({
    multi: false,
    // filter: (feature) => feature.getProperties().exist === true,
    style: () =>
      new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({
            color: "#1884f0",
          }),
        }),
      }),
  });

  map.addInteraction(select);

  const zoomToFeature = (f: Feature<Point>) => {
    const geom = f.getGeometry();
    if (geom) {
      const buf = 120;
      const [x, y] = geom.getCoordinates();
      const extent = [x - buf, y - buf, x + buf, y + buf];

      map.getView().fit(extent, {
        size: map.getSize(),
        // callback: () => {
        //   console.log("here", x, y);
        // },
      });
    }
  };

  select.on("select", ({ selected }: SelectEvent) => {
    if (selected.length > 0) {
      const feature = selected[0];
      const geom = feature.getGeometry();
      if (geom && geom.getType() === "Point") {
        renderSelectedPoint(info, feature as Feature<Point>, zoomToFeature);
      } else if (geom && geom.getType() === "MultiPolygon") {
        renderSelectedPolygon(info, feature as Feature<MultiPolygon>);
      }
    }
  });

  const coordBox = div("coords");
  root.append(coordBox);

  map.on("singleclick", (event: { coordinate: [any, any] }) => {
    const [x, y] = toLonLat(event.coordinate);
    emptyElement(coordBox);
    coordBox.append(
      events(
        div("longitude", text(" longitude "), text(numberFormat.format(x))),
        (add) =>
          add("click", () => {
            navigator.clipboard.writeText(numberFormat.format(x));
          })
      )
    );
    coordBox.append(
      events(
        div("latitude", text(" latitude "), text(numberFormat.format(y))),
        (add) =>
          add("click", () => {
            navigator.clipboard.writeText(numberFormat.format(y));
          })
      )
    );
  });

  const searchIndex: string[] = buildings.features.map(({ properties }) => {
    if (properties === null) {
      return "";
    }
    return (
      (properties.folder ?? " ") +
      colnames.map((k) => properties[k] ?? "").join(" ")
    ).toLowerCase();
  });
  const searchResult = div("result");
  const searchInput = events(input("text"), (add) =>
    add("keyup", (e) => {
      if (e.key === "Enter") {
        searchSubmit.click();
      }
    })
  );
  const searchSubmit = events(input("submit"), (add) =>
    add("click", () => {
      emptyElement(searchResult);
      const input = searchInput.value.toLowerCase().trim();
      if (input.length > 1) {
        const pat = new RegExp(`.*${input}.*`);
        const reader = new GeoJSON();
        searchIndex.map((value, index) => {
          if (pat.test(value)) {
            const feature = buildings.features[index];
            const props = feature.properties!;
            searchResult.append(
              events(div("match", text(props.folder)), (add) =>
                add("click", () => {
                  renderSelectedPoint(
                    info,
                    reader.readFeature(feature, {
                      dataProjection: "EPSG:4326",
                      featureProjection: "EPSG:3857",
                    }) as Feature<Point>,
                    zoomToFeature
                  );
                  searchInput.value = "";
                  emptyElement(searchResult);
                })
              )
            );
          }
          return;
        });
      } else {
        searchResult.append(div("err", text("il me faut au moins 2 lettres")));
      }
    })
  );
  searchSubmit.value = "chercher";
  const search = div(
    "search",
    div("input", searchInput, searchSubmit),
    searchResult
  );

  root.append(search);
};
